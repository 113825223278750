import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  CourierDetailLoad,
  CourierDetailLoadError,
  CourierDetailLoadSuccess,
  CourierDetailUpdate,
  CourierDetailUpdateError,
  CourierDetailUpdateSuccess,
  CouriersLoad,
  CouriersLoadSuccess,
  CourierTablesLoad,
  CourierTablesLoadError,
  CourierTablesLoadSuccess,
  CourierUpload,
  CourierUploadError,
  CourierUploadProgress,
  CourierUploadSuccess,
} from './actions';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { CouriersApiService } from '@common/services';
import { HttpEventType } from '@angular/common/http';
import { NotificationAdd } from '../../store';
import {
  selectCourierDetailId,
  selectCouriersDetail,
  selectCouriersSearch,
} from './selectors';

@Injectable()
export class CouriersEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly api = inject(CouriersApiService);

  public readonly couriers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CouriersLoad),
      exhaustMap(({ page }) =>
        this.store
          .select(selectCouriersSearch)
          .pipe(
            take(1),
            switchMap((search: any) => this.api.loadCouriers(page, search)),
          )
          .pipe(
            map(({ couriers, pagination }) =>
              CouriersLoadSuccess({ couriers, pagination }),
            ),
            catchError((error) =>
              of(
                NotificationAdd({
                  message: {
                    error,
                  },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public readonly couriersUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourierUpload),
      exhaustMap(({ file }) =>
        this.api.upload(file).pipe(
          tap((event) =>
            event.type === HttpEventType.UploadProgress
              ? this.store.dispatch(
                  CourierUploadProgress({
                    loaded: event.loaded,
                    total: event.total,
                  }),
                )
              : null,
          ),
          filter((event) => event.type === HttpEventType.Response),
          map(() => {
            this.store.dispatch(CourierUploadSuccess());
            return CouriersLoad({ page: 1 });
          }),
          catchError((error) => of(CourierUploadError({ error }))),
        ),
      ),
    ),
  );

  public readonly courierTablesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourierTablesLoad),
      exhaustMap(() =>
        this.store.select(selectCourierDetailId).pipe(
          filter((val) => !!val),
          take(1),
          switchMap((id: number) =>
            this.api.loadCourierTables(id).pipe(
              map(({ payments, acts }: any) =>
                CourierTablesLoadSuccess({ payments, acts }),
              ),
              catchError(() => of(CourierTablesLoadError())),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly courierLoadDetail = createEffect(() =>
    this.actions$.pipe(
      ofType(CourierDetailLoad),
      exhaustMap(({ courierId }) =>
        this.api.loadCourier(courierId).pipe(
          map((courier: any) => CourierDetailLoadSuccess({ courier })),
          catchError(() => of(CourierDetailLoadError())),
        ),
      ),
    ),
  );

  public readonly courierDetailUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourierDetailUpdate),
      exhaustMap(({ data }) =>
        this.store.select(selectCourierDetailId).pipe(
          take(1),
          switchMap((id: number) => this.api.courierUpdate(id, data)),
          map(() => CourierDetailUpdateSuccess()),
          catchError((error) => {
            this.store.dispatch(CourierDetailUpdateError());
            return of(NotificationAdd({ message: { error } }));
          }),
        ),
      ),
    ),
  );
}
