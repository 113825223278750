import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ICourier, IPagination } from '@common/interfaces';

export const CouriersSearch = createAction(
  '[Couriers Page] Search',
  props<{ search: any }>(),
);
export const CouriersLoad = createAction(
  '[Couriers Page] Load',
  props<{ page?: number }>(),
);
export const CouriersLoadSuccess = createAction(
  '[Couriers Page] Load Success',
  props<{ couriers: ICourier[]; pagination: IPagination }>(),
);
export const CouriersLoadError = createAction(
  '[Couriers Page] Load Error',
  props<{ error: HttpErrorResponse }>(),
);
export const CouriersReset = createAction('[Couriers Page] Reset');

export const CourierDetailReset = createAction(
  '[Couriers Page] Detail Error Reset',
);

export const CourierUpload = createAction(
  '[Couriers Page] Upload',
  props<{ file: Blob; fileName: string; total: number }>(),
);
export const CourierUploadSuccess = createAction(
  '[Couriers Page] Upload Success',
);
export const CourierUploadError = createAction(
  '[Couriers Page] Upload Error',
  props<{ error: HttpErrorResponse }>(),
);
export const CourierUploadProgress = createAction(
  '[Couriers Page] Upload Progress',
  props<{ loaded: number | undefined; total: number | undefined }>(),
);

export const CourierUploadValidate = createAction(
  '[Couriers Page] Upload Validate',
  props<{ file: Blob; fileName: string; total: number }>(),
);

export const CourierDetailLoad = createAction(
  '[Courier Page] Detail Load',
  props<{ courierId: number }>(),
);
export const CourierDetailLoadSuccess = createAction(
  '[Courier Page] Detail Load Success',
  props<{ courier: any }>(),
);
export const CourierDetailLoadError = createAction(
  '[Courier Page] Detail Load Error',
);
export const CourierDetailUpdate = createAction(
  '[Courier Page] Detail Update',
  props<{ data: any }>(),
);
export const CourierDetailUpdateSuccess = createAction(
  '[Courier Page] Detail Update Success',
);
export const CourierDetailUpdateError = createAction(
  '[Courier Page] Detail Update Error',
);

export const CourierTablesLoad = createAction(
  '[Couriers Page] Courier Tables Load',
);
export const CourierTablesLoadSuccess = createAction(
  '[Couriers Page] Courier Tables Load Success',
  props<{ payments: any; acts: any }>(),
);
export const CourierTablesLoadError = createAction(
  '[Couriers Page] Courier Tables Load Error',
);
