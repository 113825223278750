import { createSelector } from '@ngrx/store';
import { ICouriersInitialState } from '@common/interfaces';

export const couriers = 'couriers';
export const courierDetail = 'courierDetail';
export const courierTables = 'courierTables';

export const couriersFeature = (state: any) => state[couriers];

export const selectCouriers = createSelector(
  couriersFeature,
  (state: ICouriersInitialState) => state,
);
export const selectCouriersSearch = createSelector(
  selectCouriers,
  ({ search }: ICouriersInitialState) => search,
);
export const selectCouriersList = createSelector(
  selectCouriers,
  ({ couriers }: ICouriersInitialState) => couriers,
);
export const selectCouriersLoading = createSelector(
  selectCouriers,
  ({ isPending, hasLoaded }: ICouriersInitialState) => !hasLoaded || isPending,
);
export const selectCouriersUpload = createSelector(
  selectCouriers,
  ({ upload }: ICouriersInitialState) => upload,
);
export const selectCouriersDetail = createSelector(
  selectCouriers,
  ({ courier }: ICouriersInitialState) => courier,
);
export const selectCouriersPagination = createSelector(
  selectCouriers,
  ({ pagination }: ICouriersInitialState) => pagination,
);

export const selectCourierTablesFeature = (state: any) => state[courierTables];
export const selectCourierTables = createSelector(
  selectCourierTablesFeature,
  (state) => state,
);

export const selectCourierTablesPayments = createSelector(
  selectCourierTables,
  ({ payments }: any) => payments ?? [],
);
export const selectCourierTablesActs = createSelector(
  selectCourierTables,
  ({ acts }: any) => acts ?? [],
);

export const selectCourierDetailFeature = (state: any) => state[courierDetail];
export const selectCourierDetail = createSelector(
  selectCourierDetailFeature,
  (state) => state,
);
export const selectCourierDetailId = createSelector(
  selectCourierDetail,
  ({ id }: any) => id,
);
export const selectCourierDetailLoading = createSelector(
  selectCourierDetail,
  ({ hasLoaded, isPending }: any) => !hasLoaded || isPending,
);
export const selectCourierDetailUpdIsPending = createSelector(
  selectCourierDetail,
  ({ updateIsPending }: any) => updateIsPending,
);
