import { createReducer, on } from '@ngrx/store';
import { courierDetail, couriers, courierTables } from './selectors';
import {
  CourierDetailLoad,
  CourierDetailLoadError,
  CourierDetailLoadSuccess,
  CourierDetailReset,
  CourierDetailUpdate,
  CourierDetailUpdateError,
  CourierDetailUpdateSuccess,
  CouriersLoad,
  CouriersLoadError,
  CouriersLoadSuccess,
  CouriersReset,
  CouriersSearch,
  CourierTablesLoadSuccess,
  CourierUpload,
  CourierUploadError,
  CourierUploadProgress,
  CourierUploadSuccess,
} from './actions';
import { ICouriersInitialState } from '@common/interfaces';

const couriersInitialState: ICouriersInitialState = {
  couriers: [],
  search: null,
  pagination: null,
  hasLoaded: false,
  isPending: true,
  error: null,
  courier: null,
  upload: {
    error: null,
    total: 0,
    loaded: 0,
    fileName: null,
    isPending: false,
  },
};

export const couriersReducer = createReducer(
  couriersInitialState,
  on(CouriersSearch, (state, { search }) => ({
    ...state,
    search,
  })),
  on(CouriersLoadSuccess, (state, { couriers, pagination }) => ({
    ...state,
    couriers,
    pagination,
    isPending: false,
    hasLoaded: true,
  })),
  on(CouriersLoadError, (state, { error }) => ({
    ...state,
    error,
    isPending: false,
    hasLoaded: true,
  })),
  on(CourierUpload, (state, { fileName, total }) => ({
    ...state,
    upload: {
      ...state.upload,
      fileName,
      total,
      isPending: true,
      error: null,
    },
  })),
  on(CourierUploadProgress, (state, { total, loaded }) => ({
    ...state,
    upload: {
      ...state.upload,
      total,
      loaded,
    },
  })),
  on(CourierUploadSuccess, (state) => ({
    ...state,
    upload: {
      ...couriersInitialState.upload,
    },
  })),
  on(CourierUploadError, (state, { error }) => ({
    ...state,
    upload: {
      ...state.upload,
      isPending: false,
      error,
    },
  })),
  on(CouriersReset, (_state) => ({
    ...couriersInitialState,
  })),
);

const courierDetailInitialState: any = {
  hasLoaded: false,
  isPending: true,
  courier: null,
  id: null,
  updateIsPending: false,
};
export const courierDetailReducer = createReducer(
  courierDetailInitialState,
  on(CourierDetailLoad, (state, { courierId }) => ({
    ...state,
    id: courierId,
    isPending: true,
    hasLoaded: false,
  })),
  on(CourierDetailLoadSuccess, (state, { courier }) => ({
    ...state,
    courier,
    isPending: false,
    hasLoaded: true,
  })),
  on(CourierDetailLoadError, (state) => ({
    ...state,
    isPending: false,
    hasLoaded: true,
  })),
  on(CourierDetailUpdate, (state) => ({
    ...state,
    updateIsPending: true,
  })),
  on(CourierDetailUpdateSuccess, CourierDetailUpdateError, (state) => ({
    ...state,
    updateIsPending: false,
  })),
  on(CourierDetailUpdate, (state, { data }) => ({
    ...state,
    courier: {
      ...state.courier,
      ...data,
    },
  })),
  on(CourierDetailReset, (_state) => ({
    ...courierDetailInitialState,
  })),
);

const courierTablesInitialState: any = {
  payments: null,
  acts: null,
};
export const courierTablesReducer = createReducer(
  courierTablesInitialState,
  on(CourierTablesLoadSuccess, (state, { payments, acts }) => ({
    ...state,
    payments,
    acts,
  })),
);

export const couriersPageReducers = {
  [couriers]: couriersReducer,
  [courierDetail]: courierDetailReducer,
  [courierTables]: courierTablesReducer,
};
